import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Returns.frontend.types'

@Injectable({ providedIn: 'root' })
export class Returns_create_or_update_return_task_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { projectId: number, materialId: number, vendorLotId: number, lotId: number, packagingId: number, packagedAmount: number, warehouseId: number, chainHead: number, otherProperties?: any, sourceLicensePlateId?: number, targetLicensePlateId: number, serialNumberId?: number, sourceShippingContainerId: number, taskId?: number }): Promise<{ reasons?: string[], taskId?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (isNil(inParams.vendorLotId)) {
      missingRequiredInParams.push('\'vendorLotId\'');
    }
    if (isNil(inParams.lotId)) {
      missingRequiredInParams.push('\'lotId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (isNil(inParams.packagedAmount)) {
      missingRequiredInParams.push('\'packagedAmount\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.chainHead)) {
      missingRequiredInParams.push('\'chainHead\'');
    }
    if (isNil(inParams.targetLicensePlateId)) {
      missingRequiredInParams.push('\'targetLicensePlateId\'');
    }
    if (isNil(inParams.sourceShippingContainerId)) {
      missingRequiredInParams.push('\'sourceShippingContainerId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

