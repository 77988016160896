import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './WarehouseTransfers.frontend.types'

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_get_warehouse_transfer_status_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { warehouse_transfer_id: number }): Promise<{ reasons?: string[], status_name?: string, state_description?: string, status_id?: number, state?: number, eligibilities?: { is_outbound_processable?: boolean, is_outbound_pickable?: boolean, is_outbound_loadable?: boolean, is_outbound_completable?: boolean, is_inbound_processable?: boolean, is_inbound_receivable?: boolean, is_inbound_completable?: boolean, is_transfer_cancellable?: boolean }, details?: { has_manual_allocations?: boolean } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouse_transfer_id)) {
      missingRequiredInParams.push('\'warehouse_transfer_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

