import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Returns.frontend.types'

@Injectable({ providedIn: 'root' })
export class Returns_get_return_orders_state_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { orderIds: number[] }): Promise<{ reasons?: string[], orderStates?: { orderId?: number, failActionReasons?: { cancelReasons?: string[], completeReasons?: string[], deleteReasons?: string[], revertReasons?: string[], processReasons?: string[], unreceiveReasons?: string[], autoReceiveReasons?: string[], validateReasons?: string[] } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderIds)) {
      missingRequiredInParams.push('\'orderIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

