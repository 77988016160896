import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Cartonization_DatasourceService } from './Cartonization.datasource.index';
import { InventoryTransfers_DatasourceService } from './InventoryTransfers.datasource.index';
import { Returns_DatasourceService } from './Returns.datasource.index';
import { WarehouseTransfers_DatasourceService } from './WarehouseTransfers.datasource.index';
import { PackVerification_DatasourceService } from './PackVerification.datasource.index';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Reports_DatasourceService } from './Reports.datasource.index';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';
import { PrintNode_DatasourceService } from './PrintNode.datasource.index';
import { AsnOrders_DatasourceService } from './AsnOrders.datasource.index';
import { Attachments_DatasourceService } from './Attachments.datasource.index';
import { FootprintApiManager_DatasourceService } from './FootprintApiManager.datasource.index';

import { app_custom_ds_get_manual_allocation_tasks_automationService } from './app.datasource.index';
import { app_custom_ds_get_open_inventory_transfersService } from './app.datasource.index';
import { app_custom_ds_get_order_by_order_idService } from './app.datasource.index';
import { app_custom_ds_get_reasoncodes_by_parentId_parentEntityService } from './app.datasource.index';
import { app_custom_ds_get_sales_order_tasks_by_orderId_linenumberService } from './app.datasource.index';
import { app_ds_get_shipment_by_shipmentIdService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Cartonization = this.injector.get(Cartonization_DatasourceService);
    this.InventoryTransfers = this.injector.get(InventoryTransfers_DatasourceService);
    this.Returns = this.injector.get(Returns_DatasourceService);
    this.WarehouseTransfers = this.injector.get(WarehouseTransfers_DatasourceService);
    this.PackVerification = this.injector.get(PackVerification_DatasourceService);
    this.Notifications = this.injector.get(Notifications_DatasourceService);
    this.Reports = this.injector.get(Reports_DatasourceService);
    this.SalesOrders = this.injector.get(SalesOrders_DatasourceService);
    this.PrintNode = this.injector.get(PrintNode_DatasourceService);
    this.AsnOrders = this.injector.get(AsnOrders_DatasourceService);
    this.Attachments = this.injector.get(Attachments_DatasourceService);
    this.FootprintApiManager = this.injector.get(FootprintApiManager_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public Cartonization: Cartonization_DatasourceService;
    public InventoryTransfers: InventoryTransfers_DatasourceService;
    public Returns: Returns_DatasourceService;
    public WarehouseTransfers: WarehouseTransfers_DatasourceService;
    public PackVerification: PackVerification_DatasourceService;
    public Notifications: Notifications_DatasourceService;
    public Reports: Reports_DatasourceService;
    public SalesOrders: SalesOrders_DatasourceService;
    public PrintNode: PrintNode_DatasourceService;
    public AsnOrders: AsnOrders_DatasourceService;
    public Attachments: Attachments_DatasourceService;
    public FootprintApiManager: FootprintApiManager_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_ds_get_manual_allocation_tasks_automation: app_custom_ds_get_manual_allocation_tasks_automationService;
  public get custom_ds_get_manual_allocation_tasks_automation(): app_custom_ds_get_manual_allocation_tasks_automationService {
    if(!this._custom_ds_get_manual_allocation_tasks_automation) {
      this._custom_ds_get_manual_allocation_tasks_automation = this.injector.get(app_custom_ds_get_manual_allocation_tasks_automationService);
    }
    return this._custom_ds_get_manual_allocation_tasks_automation;
  }
  private _custom_ds_get_open_inventory_transfers: app_custom_ds_get_open_inventory_transfersService;
  public get custom_ds_get_open_inventory_transfers(): app_custom_ds_get_open_inventory_transfersService {
    if(!this._custom_ds_get_open_inventory_transfers) {
      this._custom_ds_get_open_inventory_transfers = this.injector.get(app_custom_ds_get_open_inventory_transfersService);
    }
    return this._custom_ds_get_open_inventory_transfers;
  }
  private _custom_ds_get_order_by_order_id: app_custom_ds_get_order_by_order_idService;
  public get custom_ds_get_order_by_order_id(): app_custom_ds_get_order_by_order_idService {
    if(!this._custom_ds_get_order_by_order_id) {
      this._custom_ds_get_order_by_order_id = this.injector.get(app_custom_ds_get_order_by_order_idService);
    }
    return this._custom_ds_get_order_by_order_id;
  }
  private _custom_ds_get_reasoncodes_by_parentId_parentEntity: app_custom_ds_get_reasoncodes_by_parentId_parentEntityService;
  public get custom_ds_get_reasoncodes_by_parentId_parentEntity(): app_custom_ds_get_reasoncodes_by_parentId_parentEntityService {
    if(!this._custom_ds_get_reasoncodes_by_parentId_parentEntity) {
      this._custom_ds_get_reasoncodes_by_parentId_parentEntity = this.injector.get(app_custom_ds_get_reasoncodes_by_parentId_parentEntityService);
    }
    return this._custom_ds_get_reasoncodes_by_parentId_parentEntity;
  }
  private _custom_ds_get_sales_order_tasks_by_orderId_linenumber: app_custom_ds_get_sales_order_tasks_by_orderId_linenumberService;
  public get custom_ds_get_sales_order_tasks_by_orderId_linenumber(): app_custom_ds_get_sales_order_tasks_by_orderId_linenumberService {
    if(!this._custom_ds_get_sales_order_tasks_by_orderId_linenumber) {
      this._custom_ds_get_sales_order_tasks_by_orderId_linenumber = this.injector.get(app_custom_ds_get_sales_order_tasks_by_orderId_linenumberService);
    }
    return this._custom_ds_get_sales_order_tasks_by_orderId_linenumber;
  }
  private _ds_get_shipment_by_shipmentId: app_ds_get_shipment_by_shipmentIdService;
  public get ds_get_shipment_by_shipmentId(): app_ds_get_shipment_by_shipmentIdService {
    if(!this._ds_get_shipment_by_shipmentId) {
      this._ds_get_shipment_by_shipmentId = this.injector.get(app_ds_get_shipment_by_shipmentIdService);
    }
    return this._ds_get_shipment_by_shipmentId;
  }
}

