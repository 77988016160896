import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';
import { Cartonization_ReportService } from './Cartonization.report.index';
import { InventoryTransfers_ReportService } from './InventoryTransfers.report.index';
import { Returns_ReportService } from './Returns.report.index';
import { WarehouseTransfers_ReportService } from './WarehouseTransfers.report.index';
import { PackVerification_ReportService } from './PackVerification.report.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Reports_ReportService } from './Reports.report.index';
import { SalesOrders_ReportService } from './SalesOrders.report.index';
import { PrintNode_ReportService } from './PrintNode.report.index';
import { AsnOrders_ReportService } from './AsnOrders.report.index';
import { Attachments_ReportService } from './Attachments.report.index';
import { FootprintApiManager_ReportService } from './FootprintApiManager.report.index';


@Injectable({ providedIn: 'root' })
export class app_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.Cartonization = this.injector.get(Cartonization_ReportService);
    this.InventoryTransfers = this.injector.get(InventoryTransfers_ReportService);
    this.Returns = this.injector.get(Returns_ReportService);
    this.WarehouseTransfers = this.injector.get(WarehouseTransfers_ReportService);
    this.PackVerification = this.injector.get(PackVerification_ReportService);
    this.Notifications = this.injector.get(Notifications_ReportService);
    this.Reports = this.injector.get(Reports_ReportService);
    this.SalesOrders = this.injector.get(SalesOrders_ReportService);
    this.PrintNode = this.injector.get(PrintNode_ReportService);
    this.AsnOrders = this.injector.get(AsnOrders_ReportService);
    this.Attachments = this.injector.get(Attachments_ReportService);
    this.FootprintApiManager = this.injector.get(FootprintApiManager_ReportService);
  }
    public Utilities: Utilities_ReportService;
    public Cartonization: Cartonization_ReportService;
    public InventoryTransfers: InventoryTransfers_ReportService;
    public Returns: Returns_ReportService;
    public WarehouseTransfers: WarehouseTransfers_ReportService;
    public PackVerification: PackVerification_ReportService;
    public Notifications: Notifications_ReportService;
    public Reports: Reports_ReportService;
    public SalesOrders: SalesOrders_ReportService;
    public PrintNode: PrintNode_ReportService;
    public AsnOrders: AsnOrders_ReportService;
    public Attachments: Attachments_ReportService;
    public FootprintApiManager: FootprintApiManager_ReportService;

  public app: app_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

