import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Returns_ds_get_shipping_container_by_shippingContainerCodeService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shippingContainerCode: string }): 
  Promise<{ result: { Id?: number, LookupCode?: string, LicensePlates?: { Id?: number, Archived?: boolean, WarehouseId?: number }[], Shipment?: { TypeId?: number, OrderLookups?: { ShipmentId?: number, Order?: { Id?: number, OrderClass?: { OrderClassTypeId?: number } } }[] } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shippingContainerCode)) {
      missingRequiredInParams.push('\'shippingContainerCode\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      shippingContainerCode: string    }) {
        if(isNil(inParams.shippingContainerCode)) {
          return true; 
        }
      return false;
    }
}
