import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_outbound_packing_slip_lines_contentsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId?: number, shippingContainerId?: number }): 
  Promise<{ result: { PackedQty?: number, LicensePlate?: { ShipmentId?: number, ShippingContainer?: { LookupCode?: string, Id?: number } }, Lot?: { MaterialId?: number, Material?: { LookupCode?: string, Description?: string }, VendorLot?: { LookupCode?: string, ExpirationDate?: string } }, LinePrice?: { MaterialUnitPrice?: number, MaterialId?: number }, Deliver_to_country?: { Id?: number, Country?: string, Order?: { Id?: number, ShipmentOrderLookups?: { ShipmentId?: number }[] } } }[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId?: number, shippingContainerId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { PackedQty?: number, LicensePlate?: { ShipmentId?: number, ShippingContainer?: { LookupCode?: string, Id?: number } }, Lot?: { MaterialId?: number, Material?: { LookupCode?: string, Description?: string }, VendorLot?: { LookupCode?: string, ExpirationDate?: string } }, LinePrice?: { MaterialUnitPrice?: number, MaterialId?: number }, Deliver_to_country?: { Id?: number, Country?: string, Order?: { Id?: number, ShipmentOrderLookups?: { ShipmentId?: number }[] } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId?: number, shippingContainerId?: number, $keys: { LicensePlate?: { ShippingContainer?: { LookupCode?: string, Id?: number }, ShipmentId?: number }, Lot?: { Material?: { LookupCode?: string, Description?: string }, VendorLot?: { LookupCode?: string, ExpirationDate?: string }, MaterialId?: number } }[] }): 
  Promise<{ result: { PackedQty?: number, LicensePlate?: { ShipmentId?: number, ShippingContainer?: { LookupCode?: string, Id?: number } }, Lot?: { MaterialId?: number, Material?: { LookupCode?: string, Description?: string }, VendorLot?: { LookupCode?: string, ExpirationDate?: string } }, LinePrice?: { MaterialUnitPrice?: number, MaterialId?: number }, Deliver_to_country?: { Id?: number, Country?: string, Order?: { Id?: number, ShipmentOrderLookups?: { ShipmentId?: number }[] } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId?: number, shippingContainerId?: number    }) {
      return false;
    }
}
