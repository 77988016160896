import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  ChangeDetectorRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ViewerComponent } from '@mescius/activereportsjs-angular';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';
import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Reports_ShellService } from './Reports.shell.service';
import { Reports_OperationService } from './Reports.operation.service';
import { Reports_DatasourceService } from './Reports.datasource.index';
import { Reports_FlowService } from './Reports.flow.index';
import { Reports_ReportService } from './Reports.report.index';
import { Reports_LocalizationService } from './Reports.localization.service';
import { Reports_custom_appointment_badge_report_ComponentContextService } from './Reports.custom_appointment_badge_report.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { $frontendTypes} from './Reports.frontend.types'
import { $frontendTypes as $types} from './Reports.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Reports-custom_appointment_badge_report',
  templateUrl: './Reports.custom_appointment_badge_report.component.html'
})
export class Reports_custom_appointment_badge_reportComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('reportViewer', { static: false }) reportViewer: ViewerComponent;

  report: any;
  reportParameters: any;

  inParams: { dockAppointmentId: number } = { dockAppointmentId: null };
  //#region Inputs
  @Input('dockAppointmentId') set $inParams_dockAppointmentId(v: number) {
    this.inParams.dockAppointmentId = v;
  }
  get $inParams_dockAppointmentId(): number {
    return this.inParams.dockAppointmentId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Reports_ShellService,
    private $datasources: Reports_DatasourceService,
    private $flows: Reports_FlowService,
    private $reports: Reports_ReportService,
    private $localization: Reports_LocalizationService,
    private $operations: Reports_OperationService,
    private $context: Reports_custom_appointment_badge_report_ComponentContextService,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.dockAppointmentId)) {
        this.$missingRequiredInParams.push('dockAppointmentId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'custom_appointment_badge_report';
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $report = this;
    const $utils = this.$utils;

    // We want to use reportViewer.open only in the onViewerInit, otherwise
    // the reportViewer might not be ready. In order to do that, the viewer has to be recreated.
    // We recreate it by using *ngIf="report"

    // set the report to null so that the reportViewer gets destroyed (initially this will change nothing)
    this.report = null;

    // actually destroy the viewer
    this.changeDetector.detectChanges();

    // set the report to the actual one which should create the viewer
    this.report = await this.$reports.Reports.custom_appointment_badge_report.getReport(this.inParams);
    this.reportParameters = this.$reports.Reports.custom_appointment_badge_report.getReportParameters(this.inParams);
  }

  onViewerInit() {
    this.reportViewer?.open(this.report, {
        ReportParams: this.reportParameters
      });
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    
    return result;
  }

  close() {
    this.$finish.emit();
  }
}
