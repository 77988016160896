import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class AsnOrders_ds_get_asn_orders_receiving_tasksService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderIds: number[], chainHead?: number }): 
  Promise<{ result: { Id?: number, ExpectedInventoryAmount?: number, ExpectedInventoryAmountPackId?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, LotId?: number, MaterialId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, StatusId?: number, VendorLotId?: number, ActualTargetLicensePlate?: { Id?: number, Archived?: boolean, LicensePlateContents?: { LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number }[] }, Shipment?: { Id?: number, StatusId?: number }, ShipmentLine?: { Id?: number, ActualPackagedAmount?: number, StatusId?: number }, OrderLine?: { LineNumber?: number, StatusId?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderIds)) {
      missingRequiredInParams.push('\'orderIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderIds: number[], chainHead?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ExpectedInventoryAmount?: number, ExpectedInventoryAmountPackId?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, LotId?: number, MaterialId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, StatusId?: number, VendorLotId?: number, ActualTargetLicensePlate?: { Id?: number, Archived?: boolean, LicensePlateContents?: { LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number }[] }, Shipment?: { Id?: number, StatusId?: number }, ShipmentLine?: { Id?: number, ActualPackagedAmount?: number, StatusId?: number }, OrderLine?: { LineNumber?: number, StatusId?: number } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderIds)) {
      missingRequiredInParams.push('\'orderIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderIds: number[], chainHead?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ExpectedInventoryAmount?: number, ExpectedInventoryAmountPackId?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, LotId?: number, MaterialId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, StatusId?: number, VendorLotId?: number, ActualTargetLicensePlate?: { Id?: number, Archived?: boolean, LicensePlateContents?: { LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number }[] }, Shipment?: { Id?: number, StatusId?: number }, ShipmentLine?: { Id?: number, ActualPackagedAmount?: number, StatusId?: number }, OrderLine?: { LineNumber?: number, StatusId?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderIds)) {
      missingRequiredInParams.push('\'orderIds\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderIds: number[], chainHead?: number    }) {
        if(isNil(inParams.orderIds)) {
          return true; 
        }
      return false;
    }
}
