import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Returns_ds_return_shipping_containers_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseIds?: number[], orderStatusIds?: number[], reference?: string, orderClassIds?: number[], isSourceShippingContainer?: boolean, statusIds?: number[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Shipment?: { Id?: number, OrderLookups?: { CreatedSysDateTime?: string, Order?: { LookupCode?: string, Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } } }[] }, LicensePlates?: { Id?: number, Archived?: boolean, ParentId?: number, TypeId?: number, Location?: { Id?: number, Name?: string } }[] }[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseIds?: number[], orderStatusIds?: number[], reference?: string, orderClassIds?: number[], isSourceShippingContainer?: boolean, statusIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Shipment?: { Id?: number, OrderLookups?: { CreatedSysDateTime?: string, Order?: { LookupCode?: string, Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } } }[] }, LicensePlates?: { Id?: number, Archived?: boolean, ParentId?: number, TypeId?: number, Location?: { Id?: number, Name?: string } }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseIds?: number[], orderStatusIds?: number[], reference?: string, orderClassIds?: number[], isSourceShippingContainer?: boolean, statusIds?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Shipment?: { Id?: number, OrderLookups?: { CreatedSysDateTime?: string, Order?: { LookupCode?: string, Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } } }[] }, LicensePlates?: { Id?: number, Archived?: boolean, ParentId?: number, TypeId?: number, Location?: { Id?: number, Name?: string } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseIds?: number[], orderStatusIds?: number[], reference?: string, orderClassIds?: number[], isSourceShippingContainer?: boolean, statusIds?: number[]    }) {
      return false;
    }
}
