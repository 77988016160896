import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_get_dock_appointment_by_orderIdService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId?: number }): 
  Promise<{ result: { Id?: number, DockAppointmentId?: number, ItemEntityId?: number, ItemEntityType?: string, DockAppointment?: { CheckedInOn?: string, EstimatedPallets?: number, LookupCode?: string, ScheduledArrival?: string, SealNumber?: string, StatusId?: number, TrailerNumber?: string, AssignedLocation?: { Name?: string, ShortName?: string }, ScheduledLocation?: { Name?: string, ShortName?: string }, ScheduledCarrier?: { Name?: string } } } }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId?: number    }) {
      return false;
    }
}
