import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_outbound_packing_slip_get_warehouse_address_by_warehouseIdService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseId: number }): 
  Promise<{ result: { WarehouseId?: number, ContactId?: number, Contact?: { Id?: number, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, Line3?: string, PostalCode?: string, State?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { warehouseId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { WarehouseId?: number, ContactId?: number, Contact?: { Id?: number, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, Line3?: string, PostalCode?: string, State?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      warehouseId: number    }) {
        if(isNil(inParams.warehouseId)) {
          return true; 
        }
      return false;
    }
}
