import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { AsnOrders_DatasourceService } from './AsnOrders.datasource.index';

interface IAsnOrders_license_plate_label_reportServiceInParams {
  licensePlateId: number}

interface IAsnOrders_license_plate_label_reportServiceData {
  LicensePlates?: { result?: { Id?: number, ActualPackagedAmount?: number, ExpectedPackagedAmount?: number, ActualTargetLicensePlate?: { LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Material?: { LookupCode?: string } }, ActualPackagedPack?: { ShortName?: string }, Order?: { LookupCode?: string, Project?: { LookupCode?: string } } }[] }
}

@Injectable({ providedIn: 'root' })
export class AsnOrders_license_plate_label_reportService extends ReportBaseService<IAsnOrders_license_plate_label_reportServiceInParams, IAsnOrders_license_plate_label_reportServiceData> {

  protected reportReferenceName = 'license_plate_label_report';
  protected appReferenceName = 'AsnOrders';

  constructor(
    $utils: UtilsService,
    private $datasources: AsnOrders_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IAsnOrders_license_plate_label_reportServiceInParams) {
  }

  protected async getData(inParams: IAsnOrders_license_plate_label_reportServiceInParams): Promise<IAsnOrders_license_plate_label_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IAsnOrders_license_plate_label_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IAsnOrders_license_plate_label_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        licensePlateId:  $report.inParams.licensePlateId 
      };
      
      const dsData = await this.$datasources.AsnOrders.ds_license_plate_label_report.get(dsParams);
      
      data.LicensePlates = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
