import { IEnvironmentInfo, IComponentInfo, IPackageInfo, IApplicationInfo, ComponentType } from './app-context.service';
import { Reports_ModuleContextService } from './Reports.context.service';

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class Reports_custom_transaction_report_ComponentContextService {
  private readonly moduleContext: Reports_ModuleContextService;

  constructor(moduleContext: Reports_ModuleContextService) {
    this.moduleContext = moduleContext;
  }

  public get app(): IApplicationInfo {
    return this.moduleContext.app;
  }

  public get env(): IEnvironmentInfo {
    return this.moduleContext.env;
  }

  public get package(): IPackageInfo {
    return this.moduleContext.package;
  }

  public get component(): IComponentInfo {
    return {
      name: 'custom_transaction_report',
      type: ComponentType.report
    }
  }
}