import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_get_order_by_order_idService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { order_id: number }): 
  Promise<{ result: { Id?: number, AccountId?: number, OrderClassId?: number, OwnerReference?: string, ProjectId?: number, VendorReference?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { AccountId?: number, ActualWarehouseId?: number, CarrierId?: number, CarrierServiceTypeId?: number, LookupCode?: string, WaveId?: number, Tasks?: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, ActualTargetShippingContainerId?: number, CompletedDateTime?: string, Employee?: string, ExpectedInventoryAmount?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, GrossVolume?: number, GrossWeight?: number, LotId?: number, MaterialId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, PickSlipId?: number, ProjectId?: number, StartDateTime?: string, StatusId?: number, VendorLotId?: number, VolumeUomId?: number, WarehouseId?: number, WeightUomId?: number }[], LicensePlates?: { Id?: number, ShippingContainerId?: number, LicensePlateContents?: { PackagedId?: number, Amount?: number, PackagedAmount?: number, Lot?: { Id?: number, MaterialId?: number, VendorLotId?: number } }[] }[] } }[], Addresses?: { Id?: number, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, Greeting?: string, IsResidential?: boolean, LastName?: string, Line1?: string, Line2?: string, Line3?: string, Line4?: string, LookupCode?: string, MiddleName?: string, Name?: string, Notes?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, ReferenceCode?: string, State?: string, Title?: string, TypeId?: number, Url?: string }[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.order_id)) {
      missingRequiredInParams.push('\'order_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      order_id: number    }) {
        if(isNil(inParams.order_id)) {
          return true; 
        }
      return false;
    }
}
