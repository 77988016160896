import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './PackVerification.frontend.types'

@Injectable({ providedIn: 'root' })
export class PackVerification_unpick_unverified_shipping_container_content_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { lotId: number, packagingId: number, startEndTaskId: number, licenseplateId: number, targetLicensePlateId: number }): Promise<{ reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.lotId)) {
      missingRequiredInParams.push('\'lotId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (isNil(inParams.startEndTaskId)) {
      missingRequiredInParams.push('\'startEndTaskId\'');
    }
    if (isNil(inParams.licenseplateId)) {
      missingRequiredInParams.push('\'licenseplateId\'');
    }
    if (isNil(inParams.targetLicensePlateId)) {
      missingRequiredInParams.push('\'targetLicensePlateId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

