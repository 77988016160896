import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_custom_close_eligible_inventory_transfers_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: {  }): Promise<{ results?: { closed: { inventory_transfer_id: number }[], skipped: { inventory_transfer_id: number, reason: string }[] } }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = null;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

